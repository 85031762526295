$black-a-10: rgba(#000000, 0.1);
$black-a-20: rgba(#000000, 0.2);
$black-a-30: rgba(#000000, 0.3);
$black-a-40: rgba(#000000, 0.4);
$black-a-50: rgba(#000000, 0.5);
$black-a-60: rgba(#000000, 0.6);
$black-a-70: rgba(#000000, 0.7);
$black-a-80: rgba(#000000, 0.8);
$black-a-90: rgba(#000000, 0.9);

$white-a-05: rgba(#ffffff, 0.05);
$white-a-10: rgba(#ffffff, 0.1);
$white-a-20: rgba(#ffffff, 0.2);
$white-a-30: rgba(#ffffff, 0.3);
$white-a-40: rgba(#ffffff, 0.4);
$white-a-50: rgba(#ffffff, 0.5);
$white-a-60: rgba(#ffffff, 0.6);
$white-a-70: rgba(#ffffff, 0.7);
$white-a-80: rgba(#ffffff, 0.8);
$white-a-90: rgba(#ffffff, 0.9);

:root {
  --background-deepest: #111111;
  --error: #c32d2e;
  --error-page-text: #ffffff;
  --black-a-10: #{$black-a-10};
  --black-a-20: #{$black-a-20};
  --black-a-30: #{$black-a-30};
  --black-a-40: #{$black-a-40};
  --black-a-50: #{$black-a-50};
  --black-a-60: #{$black-a-60};
  --black-a-70: #{$black-a-70};
  --black-a-80: #{$black-a-80};
  --black-a-90: #{$black-a-90};

  --white-a-05: #{$white-a-05};
  --white-a-10: #{$white-a-10};
  --white-a-20: #{$white-a-20};
  --white-a-30: #{$white-a-30};
  --white-a-40: #{$white-a-40};
  --white-a-50: #{$white-a-50};
  --white-a-60: #{$white-a-60};
  --white-a-70: #{$white-a-70};
  --white-a-80: #{$white-a-80};
  --white-a-90: #{$white-a-90};
}


@font-face {
  font-family: trattatello;
  src: url('../font/Trattatello.woff2') format('truetype'); /* 載入自定義字體文件 */
  font-display: swap;
}

.animate-fade-in {
  animation: fadeInCenter 1s ease-in-out;
  @media screen and (min-width: 1025px) {
    animation: fadeIn 1s ease-in-out;
  }
}

.animate-fade-in-position {
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
}

.animate-fade-right-position {
  -webkit-transform: translate3d(-30px, 0, 0);
  transform: translate3d(-30px, 0 , 0);
}

.animate-fade-left-position {
  -webkit-transform: translate3d(30px, 0, 0);
  transform: translate3d(30px, 0 , 0);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 30px, 0);
    transform: translate3d(-50%, 30px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}